// Note: Requires flexslider 2 buddy.
jQuery(document).ready(function ($) {
	$('.mixed-content-slider-wrapper:not(.one-slide) .flexslider').flexslider({
		animation: "slide",
		prevText: "",
		nextText: "",
		controlNav: false,
		// customDirectionNav: $(".mixed-content-controls .mixed-content-prev, .mixed-content-controls .mixed-content-next"),
		directionNav: false,
	});

	$('.mixed-content-slider-wrapper:not(.one-slide)').each(function () {
		var $thisContainer = $(this);
		$thisContainer.find('.mixed-content-prev').click(function () {
			$thisContainer.find('.flexslider').flexslider('next');
		});

		$thisContainer.find('.mixed-content-next').click(function () {
			$thisContainer.find('.flexslider').flexslider('prev');
		});
	});

}); /* end of as page load scripts */